html {
    overflow-x: hidden;
}

body {
    padding: 0;
    margin: 0;
    font-size: 14px;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

.paypal-buttons {
    margin: auto;
}
